<script>
import {login} from '@/request/api/login'
import { mapMutations } from 'vuex';
export default {
    data(){
        return {
            checked:false,//记住密码
            loading:false,
            form:{
                username:'',
                password:'',
            },
            rules:{
                username:[
                    { required: true, message: '用户名必填', trigger: 'blur' },
                ],
                password:[
                    { required: true, message: '密码必填', trigger: 'blur' },
                ],
            }
        }
    },
    created(){},
    methods:{
        ...mapMutations({'vuexLogin':'login'}),
        //
        handleSubmit(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.req()
                } else {
                    return false;
                }
            });
            
        },
        async req(){
            this.loading = true
            let {code,msg,data} = await login(this.form)
            this.loading = false
            if(code!=0) return this.$message.error(msg)
            const storeData = {token:data.token,username:data.user.username,id:data.user.id};
            this.vuexLogin(storeData)
            window.location.href='/'
            // setInterval(()=>{
            //     window.location.href='/'
            // },500)
            this.$notify({title: '成功',message: msg,type: 'success'});
        }
    }
}
</script>
<template>
    <div class="max-login d-flex a-center j-center flex-column">
        <div class="login">
            <div class="title">{{ $win.mconfig.name }}</div>
            <div class="login-title d-flex a-center j-center">账号登录</div>
            <div class="outInput">
                <el-form ref="form" :rules="rules" :model="form" label-width="80px">
                    <el-form-item label="用户名" prop="username">
                        <el-input v-model="form.username" placeholder="请输入用户名"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <el-input v-model="form.password" placeholder="请输入密码" show-password></el-input>
                    </el-form-item>
                    <div style="margin-top: 10px; margin-left: 80px;">
                        <el-checkbox v-model="checked">记住密码</el-checkbox>
                    </div>
                    <div style="margin-top: 10%;">
                        <el-button @click="handleSubmit" class="my_submit" type="primary" round :loading="loading">登录</el-button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.max-login {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(../assets/imgs/login_bg.png) no-repeat center;
    background-size: cover;
    .title {
        color: #0076FE;
        font-size: 70px;
        position: absolute;
        left: 0;
        bottom: 120%;
        width: 100%;
        text-align: center;
        font-weight: bolder;
        letter-spacing: 20px;
    }
    .login {
        position: relative;
        width: 620px;
        height: 405px;
        background: url(../assets/imgs/login_box.png) no-repeat center;
        background-size:100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .login-title {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            color: #ffffff;
            text-align: center;
            height: 13%;
            font-size: 22px;
        }
        .outInput {
            height: 45%;
            width: 80%;
            // background-color: #699;
        }
    }
}
.my_submit {
    display: block;margin: auto;background-color: #3A83EA;color: #ffffff;padding-right: 48px;;padding-left: 48px;font-size: 16px;
}
@media screen and(max-width:769px) {
    .login {width: 100%!important;margin: auto;height: 40%!important;}
    .title {font-size: 40px!important;}
}
@media screen and(max-height:760px) {
    .login {
        height: 355px!important;
        top: 30px;
    }
    .title {
        bottom: 110%!important;
        font-size: 50px!important;
    }
}
</style>